import React, { useMemo, useState } from "react"
import {graphql, useStaticQuery} from "gatsby"

import Layout from '../../components/layout'
import Head from '../../components/head'
import hfaStyles from '../../components/nfl-home-field-advantage-hfa-tracker.module.scss'
import DisplayHfa from "../../helper_js/hfa_display"
import Select from "react-select"
import HfaChart from "../../helper_js/hfa_chart"


const HfaTracker = () => {

    const data = useStaticQuery(graphql`
        query {
            allHfaSummaryCsv {
                nodes {
                    current_season_hfa
                    hfa_l48
                    hfa_l80
                    hfa_l160                  
                }
            }
            uniqueTeams: allHfaStadiumsTimeSeriesCsv {
                distinct (
                    field: team_nick
                )
            }
            allHfaStadiumsCsv {
                nodes {
                    team
                    team_logo_espn
                    stadium
                    stadium_id
                    record
                    hfa_l16
                    hfa_l48
                    hfa_l80
                    hfa_all_time
                    current
                }
            }
            allHfaStadiumsTimeSeriesCsv {
                nodes {
                    team_nick
                    week_of
                    hfa_l80
                    team_color
                }
            }
        }
    `)
    
    // league averages for shading //
    const leagueHfaCur = data.allHfaSummaryCsv.nodes[0]['current_season_hfa']
    const leagueHfa48 = data.allHfaSummaryCsv.nodes[0]['hfa_l48']
    const leagueHfa80 = data.allHfaSummaryCsv.nodes[0]['hfa_l80']
    const leagueHfa160 = data.allHfaSummaryCsv.nodes[0]['hfa_l160']

    // drop for chart //
    const dropTeam = data.uniqueTeams.distinct.map((node) => {
        return(
            {'value' : node, 'label' : node}
        )
    })

    const [selectedTeam, setSelectedTeam] = useState({'value' : 'League Average', 'label' : 'League Average'})

    // sorting mechanics //
    // state //
    const [sortField, setSortField] = useState({
        'field' : 'hfa_l48',
        'direction' : 'dsc'
    })

    // func for handling click //
    const handleSortRequest = (field) => {

        var direction = 'dsc'

        if (sortField['field'] === field && sortField['direction'] === 'dsc') {
            direction = 'asc'
        }

        setSortField({
            'field' : field,
            'direction' : direction
        })
    }

    // func for sorting copy of data //
    // will fire whenevr state changes //
    const sortedData = useMemo(() => {

        // copy data //
        let sortableData = [...data.allHfaStadiumsCsv.nodes]
        
        // sort //
        sortableData.sort((a, b) => {
            // get nans out //

            var firstVal = parseFloat(a[sortField['field']])
            var secondVal = parseFloat(b[sortField['field']])

            if (isNaN(firstVal)) {
                firstVal = 0
            }

            if (isNaN(secondVal)) {
                secondVal = 0
            }

            if (firstVal < secondVal) {
                return sortField['direction'] === 'asc' ? -1 : 1
            } else if (firstVal > secondVal) {
                return sortField['direction'] === 'asc' ? 1 : -1
            } else {
                return 0
            }
        })

        // return sorted data //
        return sortableData

    }, [data.allHfaStadiumsCsv.nodes, sortField])

    return(
        <Layout>
            <Head
                title="NFL Home Field Advantage HFA Tracker"
                pageDescription="A dashboard to  display current and historical HFA in the NFL"
            />
            <div className={hfaStyles.hfa_all_container}>
                <div className={hfaStyles.title_div}>
                    Summary
                </div>
                <div className={hfaStyles.hfa_container}>
                    <div className={hfaStyles.hfa_div}>
                        <table className={hfaStyles.summary_table}>
                            <tbody>
                                <tr className={hfaStyles.summary_table_metrics}>
                                    <td>
                                        {parseFloat(leagueHfaCur).toFixed(2)}
                                    </td>
                                    <td>
                                        {parseFloat(leagueHfa48).toFixed(2)}
                                    </td>
                                    <td>
                                        {parseFloat(leagueHfa80).toFixed(2)}
                                    </td>
                                    <td>
                                        {parseFloat(leagueHfa160).toFixed(2)}
                                    </td>
                                </tr>
                                <tr className={hfaStyles.summary_table_titles}>
                                    <td>
                                        Current Season
                                    </td>
                                    <td>
                                        Last 3 Seasons
                                    </td>
                                    <td>
                                        Last 5 Seasons
                                    </td>
                                    <td>
                                        Last 10 Seasons
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className={hfaStyles.title_div}>
                    Historical HFA (Rolling 5 Seasons)
                </div>
                <div className={hfaStyles.hfa_container}>
                    <div className={hfaStyles.hfa_div}>
                        <div className={hfaStyles.selectContainerDiv}>
                            <Select
                                options={dropTeam}
                                onChange={setSelectedTeam}
                                placeholder='Select Team'
                            />
                        </div>
                        <HfaChart
                            data={data.allHfaStadiumsTimeSeriesCsv}
                            team={selectedTeam['value']}
                        />
                    </div>
                </div>
                <div className={hfaStyles.title_div}>
                    Current Stadiums
                </div>
                <div className={hfaStyles.hfa_container}>
                    <div className={hfaStyles.hfa_div}>
                        <table className={hfaStyles.hfa_table}>
                            <thead>
                                <tr className={hfaStyles.header_row_group}>
                                    <th colSpan={2} aria-label='spacing'></th>
                                    <th colSpan={1} aria-label='spacing'></th>
                                    <th colSpan={4}>
                                        <div className={hfaStyles.header_row_group_div}>
                                            Home Field Advantage
                                        </div>
                                    </th>
                                </tr>
                                <tr className={hfaStyles.header_row}>
                                    <th colSpan={2}>
                                        Stadium
                                    </th>
                                    <th colSpan={1}>
                                        Record
                                    </th>
                                    <th>
                                        <button
                                            type='button'
                                            className={hfaStyles.sortButton}
                                            onClick={() => handleSortRequest('hfa_l16')}
                                        >
                                            Last 16 Weeks {sortField['field'] === 'hfa_l16' ? sortField['direction'] === 'asc' ? <span className={hfaStyles.sortSpan}>&#11014;</span> : <span className={hfaStyles.sortSpan}>&#11015;</span> : ''}
                                        </button>
                                    </th>
                                    <th>
                                        <button
                                            type='button'
                                            className={hfaStyles.sortButton}
                                            onClick={() => handleSortRequest('hfa_l48')}
                                        >
                                            Last 3 Seasons {sortField['field'] === 'hfa_l48' ? sortField['direction'] === 'asc' ? <span className={hfaStyles.sortSpan}>&#11014;</span> : <span className={hfaStyles.sortSpan}>&#11015;</span> : ''}
                                        </button>
                                    </th>
                                    <th>
                                        <button
                                            type='button'
                                            className={hfaStyles.sortButton}
                                            onClick={() => handleSortRequest('hfa_l80')}
                                        >
                                            Last 5 Seasons {sortField['field'] === 'hfa_l80' ? sortField['direction'] === 'asc' ? <span className={hfaStyles.sortSpan}>&#11014;</span> : <span className={hfaStyles.sortSpan}>&#11015;</span> : ''}
                                        </button>
                                    </th>
                                    <th>
                                        <button
                                            type='button'
                                            className={hfaStyles.sortButton}
                                            onClick={() => handleSortRequest('hfa_all_time')}
                                        >
                                            All Time {sortField['field'] === 'hfa_all_time' ? sortField['direction'] === 'asc' ? <span className={hfaStyles.sortSpan}>&#11014;</span> : <span className={hfaStyles.sortSpan}>&#11015;</span> : ''}
                                        </button>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {sortedData.map((node, index) => {
                                    
                                    if (parseInt(node.current) === 1) {

                                        return (
                                            <tr className={hfaStyles.hfa_row} key={node.team + '_' + node.stadium_id}>
                                                <td>
                                                    <div className={hfaStyles.team_logo_div}>
                                                        <img
                                                            className={hfaStyles.team_logo}
                                                            src={node.team_logo_espn}
                                                            alt={node.team}>
                                                        </img>
                                                    </div>
                                                </td>
                                                <td>
                                                    {node.stadium}
                                                </td>
                                                <td>
                                                    {node.record}
                                                </td>
                                                <td>
                                                    <DisplayHfa
                                                        hfa={node.hfa_l16}
                                                        mid={leagueHfa48}
                                                    />
                                                </td>
                                                <td>
                                                    <DisplayHfa
                                                        hfa={node.hfa_l48}
                                                        mid={leagueHfa48}
                                                    />
                                                </td>
                                                <td>
                                                    <DisplayHfa
                                                        hfa={node.hfa_l80}
                                                        mid={leagueHfa80}
                                                    />
                                                </td>
                                                <td>
                                                    <DisplayHfa
                                                        hfa={node.hfa_all_time}
                                                        mid={leagueHfa160}
                                                    />
                                                </td>
                                            </tr>
                                        )

                                    } else {

                                        return(
                                            null
                                        )
                                    }
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className={hfaStyles.title_div}>
                    Historic Stadiums
                </div>
                <div className={hfaStyles.hfa_container}>
                    <div className={hfaStyles.hfa_div}>
                        <table className={hfaStyles.hfa_table}>
                            <thead>
                                <tr className={hfaStyles.header_row_group}>
                                    <th colSpan={2} aria-label='spacing'></th>
                                    <th colSpan={1} aria-label='spacing'></th>
                                    <th colSpan={4}>
                                        <div className={hfaStyles.header_row_group_div}>
                                            Home Field Advantage
                                        </div>
                                    </th>
                                </tr>
                                <tr className={hfaStyles.header_row}>
                                    <th colSpan={2}>
                                        Stadium
                                    </th>
                                    <th colSpan={1}>
                                        Record
                                    </th>
                                    <th>
                                        <button
                                            type='button'
                                            className={hfaStyles.sortButton}
                                            onClick={() => handleSortRequest('hfa_l16')}
                                        >
                                            Last 16 Weeks {sortField['field'] === 'hfa_l16' ? sortField['direction'] === 'asc' ? <span className={hfaStyles.sortSpan}>&#11014;</span> : <span className={hfaStyles.sortSpan}>&#11015;</span> : ''}
                                        </button>
                                    </th>
                                    <th>
                                        <button
                                            type='button'
                                            className={hfaStyles.sortButton}
                                            onClick={() => handleSortRequest('hfa_l48')}
                                        >
                                            Last 3 Seasons {sortField['field'] === 'hfa_l48' ? sortField['direction'] === 'asc' ? <span className={hfaStyles.sortSpan}>&#11014;</span> : <span className={hfaStyles.sortSpan}>&#11015;</span> : ''}
                                        </button>
                                    </th>
                                    <th>
                                        <button
                                            type='button'
                                            className={hfaStyles.sortButton}
                                            onClick={() => handleSortRequest('hfa_l80')}
                                        >
                                            Last 5 Seasons {sortField['field'] === 'hfa_l80' ? sortField['direction'] === 'asc' ? <span className={hfaStyles.sortSpan}>&#11014;</span> : <span className={hfaStyles.sortSpan}>&#11015;</span> : ''}
                                        </button>
                                    </th>
                                    <th>
                                        <button
                                            type='button'
                                            className={hfaStyles.sortButton}
                                            onClick={() => handleSortRequest('hfa_all_time')}
                                        >
                                            All Time {sortField['field'] === 'hfa_all_time' ? sortField['direction'] === 'asc' ? <span className={hfaStyles.sortSpan}>&#11014;</span> : <span className={hfaStyles.sortSpan}>&#11015;</span> : ''}
                                        </button>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {sortedData.map((node, index) => {
                                    
                                    if (parseInt(node.current) === 0) {

                                        return (
                                            <tr className={hfaStyles.hfa_row} key={node.team + '_' + node.stadium_id}>
                                                <td>
                                                    <div className={hfaStyles.team_logo_div}>
                                                        <img
                                                            className={hfaStyles.team_logo}
                                                            src={node.team_logo_espn}
                                                            alt={node.team}>
                                                        </img>
                                                    </div>
                                                </td>
                                                <td>
                                                    {node.stadium}
                                                </td>
                                                <td>
                                                    {node.record}
                                                </td>
                                                <td>
                                                    {parseFloat(node.hfa_l16).toFixed(2)}
                                                </td>
                                                <td>
                                                    {parseFloat(node.hfa_l48).toFixed(2)}
                                                </td>
                                                <td>
                                                    {parseFloat(node.hfa_l80).toFixed(2)}
                                                </td>
                                                <td>
                                                    {parseFloat(node.hfa_all_time).toFixed(2)}
                                                </td>
                                            </tr>
                                        )

                                    } else {

                                        return(
                                            null
                                        )
                                    }
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </Layout>
    )

}

export default HfaTracker
