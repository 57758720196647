import React from "react"

const DisplayHfa = ({hfa, mid}) => {

    var parsedHfa = parseFloat(hfa).toFixed(2)
    var parsedMid = parseFloat(mid)

    if (isNaN(parsedHfa)) {
        return (
            <div
                style={{
                    width: '60%',
                    height: '75%',
                    margin: '0 auto',
                    borderRadius: '15px',
                    background: 'white',
                    color: '#adadad',
                    display: 'flex',
                    justifyContent: 'center',
                    alignContent: 'center',
                    flexDirection: 'column',
                    fontSize: '.8rem'
                }}
            >
                --
            </div>
        )

    } else if (parsedHfa >= parsedMid + 1.5) {
        return (
            <div
                style={{
                    width: '60%',
                    height: '75%',
                    margin: '0 auto',
                    borderRadius: '15px',
                    background: '#e8fcf6',
                    color: '#259c72',
                    display: 'flex',
                    justifyContent: 'center',
                    alignContent: 'center',
                    flexDirection: 'column',
                    fontSize: '.8rem',
                    fontWeight: 'bold',
                }}
            >
                {parsedHfa}
            </div>
        )
    } else if (parsedHfa <= parsedMid - 1.5) {
        return (
            <div
                style={{
                    width: '60%',
                    height: '75%',
                    margin: '0 auto',
                    borderRadius: '15px',
                    background: '#fae3eb',
                    color: '#bf2a5e',
                    display: 'flex',
                    justifyContent: 'center',
                    alignContent: 'center',
                    flexDirection: 'column',
                    fontSize: '.8rem',
                    fontWeight: 'bold'
                }}
            >
                {parsedHfa}
            </div>
        )
    } else {
        return (
            <div
                style={{
                    width: '60%',
                    height: '75%',
                    margin: '0 auto',
                    borderRadius: '15px',
                    background: 'white',
                    color: '#636363',
                    display: 'flex',
                    justifyContent: 'center',
                    alignContent: 'center',
                    flexDirection: 'column',
                    fontSize: '.8rem'
                }}
            >
                {parsedHfa}
            </div>
        )
    }
    
}

export default DisplayHfa