import React from 'react'
import { VictoryLine, VictoryChart, VictoryAxis, VictoryLegend, VictoryLabel } from 'victory'



const HfaChart = ( { data, team } ) => {

    // a filler array for future indexing //
    const indexingArray = ['league', 'team']

    const leagueData = data.nodes.filter(
        node => (node.team_nick === 'League Average' && parseFloat(node.week_of) >= 2000)
    ).map(node => {

            return (
                {
                    week_of: parseFloat(node.week_of),
                    hfa: parseFloat(node.hfa_l80),
                    team_color: node.team_color,
                    team_name : node.team_nick ,
                    zero_point: 0
                }
            )

    })

    const teamData = data.nodes.filter(
        node => (node.team_nick === team && parseFloat(node.week_of) >= 2000)
    ).map(node => {

            // handle nans which victory chart doesnt like //
            if (isNaN(parseFloat(node.hfa_l80))) {

                return (
                    {
                        week_of: parseFloat(node.week_of),
                        hfa: null,
                        team_color: node.team_color,
                        team_name : node.team_nick ,
                        zero_point: 0
                    }
                )

            } else {

                return (
                    {
                        week_of: parseFloat(node.week_of),
                        hfa: parseFloat(node.hfa_l80),
                        team_color: node.team_color,
                        team_name : node.team_nick ,
                        zero_point: 0
                    }
                )

            }

    })

    // initiate chart struct with league averages //
    var legendData = [
        {
            'name' : 'League Average',
            'strokeColor' : '#000000',
            'strokeOpac' : 1,
        }
    ]
    var lineStyles = [
        {
            data: {
                stroke : '#000000',
                opacity: .75,
            },
            labels: {
                fill: '#000000',
                fontSize: 12,
            },
        }
    ]

    // add line data //
    var lineData = [
        leagueData,
        teamData
    ]

    // extract meta from team line data and push to legend and styles //
    var teamColor = lineData[1][0]['team_color']
    // var teamColor = '#123456'
    legendData.push(
        {
            'name' : team,
            'strokeColor' : teamColor,
            'strokeOpac' : 1,
        }
    )
    lineStyles.push(
        {
            data: {
                stroke : teamColor,
                opacity: .75,
            },
            labels: {
                fill: teamColor,
                fontSize: 12,
            },
        }
    )


    return (

        <div>
            <VictoryChart
                domainPadding={{
                    y:[15,15],
                    x:[0,15]
                }}
                width={700}
                height={300}
            >
                <VictoryLegend
                    orientation='horizontal'
                    x={50}
                    y={10}
                    symbolSpacer={6}
                    data={legendData}
                    style={{
                        data: {
                            fill : 'none',
                            stroke : ({datum}) => datum.strokeColor,
                            opacity : ({datum}) => datum.strokeOpac,
                            strokeDasharray : ({datum}) => datum.strokeDash,
                        },
                        labels: {
                            fill : ({datum}) => datum.strokeColor,
                            opacity : ({datum}) => datum.strokeOpac,
                            fontSize: 10,
                        }
                    }}
                />
                <VictoryAxis
                    label='Season'
                    style={{
                        axisLabel: {
                            fontSize: '12px',
                            padding: '30',
                        },
                        tickLabels: {
                            fontSize: '10px',
                            color: '#808080',
                        },
                    }}
                    orientation='bottom'
                    offsetY={50}
                />
                <VictoryAxis
                    dependentAxis
                    style={{
                        tickLabels: {
                            fontSize: '10px',
                            color: '#808080',
                        }
                }}
                />
                {indexingArray.map((row) => {
                    return(
                        <VictoryLine
                            style={lineStyles[indexingArray.indexOf(row)]}
                            data={lineData[indexingArray.indexOf(row)]}
                            x='week_of'
                            y='hfa'
                            labelComponent={
                                <VictoryLabel
                                    textAnchor='start'
                                    verticalAnchor='middle'
                                    dy={-3}
                                    renderInPortal={true}
                                    backgroundStyle = {{
                                        fill: 'white',
                                        opacity: .75,
                                    }}
                                    backgroundPadding={{
                                        left:1,
                                        bottom:3,
                                    }}
                                />
                            }
                        />
                    )
                })}
            </VictoryChart>
        </div>

    )
}

export default HfaChart